module directives {
    export module applicationcore {
        interface IDescriptionScope extends ng.IScope {
            description: string;
            id: number;
        }

        export class descriptionDirective implements ng.IDirective {

            template = `{{description}}`;
            scope = { id: "=" }

            constructor(private descriptionService: interfaces.applicationcore.IDescriptionService) {
            }

            link = ($scope: IDescriptionScope, $element: ng.IAugmentedJQuery) => {
                this.descriptionService.getDescription($scope.id).then((data) => {
                    $scope.description = data.LocaleDescription;
                });
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (descriptionService) => new descriptionDirective(descriptionService);
                directive.$inject = ['descriptionService'];

                return directive;
            }
        }

        angular.module("app").directive("gtsDescription", descriptionDirective.factory());
    }
}